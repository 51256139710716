<template>
  <b-modal
    id="edit-servomotor-setting-modal"
    hide-footer
    title="Actualizar configuración"
  >
    <servomotor-setting-form
      :servomotor-setting-to-edit="servomotorSetting"
      @on-confirm="onConfirmForm"
    />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ServomotorSettingForm from '@/views/apps/device/components/ServomotorSettingForm.vue'

export default {
  components: {
    ServomotorSettingForm,
    BModal,
  },
  props: {
    servomotorSetting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    async onConfirmForm(servomotorSetting) {
      try {
        await this.$store.dispatch(
          'deviceSettingsModule/updateServomotorSetting',
          servomotorSetting,
        )
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Configuarción actualizada',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Los datos se han guardado correctamente',
          },
        })
        this.$emit('on-servomotor-setting-updated', servomotorSetting)
        this.$bvModal.hide('edit-servomotor-setting-modal')
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Algo salió mal...',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Verifica los datos introducidos y tu conexión a internet',
          },
        })
      }
    },
  },
}
</script>
