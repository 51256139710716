<template>
  <validation-observer ref="deviceForm">
    <b-form>
      <b-form-group
        :disabled="disabled"
        label="Nombre"
      >
        <validation-provider
          #default="{ errors }"
          name="nombre"
          rules="required"
        >
          <b-form-input
            v-model="computedServomotorSetting.name"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :disabled="disabled"
        label="Alias"
      >
        <validation-provider
          #default="{ errors }"
          name="alias"
          rules="required"
        >
          <b-form-input
            v-model="computedServomotorSetting.alias"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :disabled="disabled"
        label="Inclinación inicial"
      >
        <validation-provider
          #default="{ errors }"
          name="inclinación inicial"
          rules="required|positive"
        >
          <b-form-input
            v-model="computedServomotorSetting.initialInclination"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :disabled="disabled"
        label="Inclinación final"
      >
        <validation-provider
          #default="{ errors }"
          name="inclinación final"
          rules="required|positive"
        >
          <b-form-input
            v-model="computedServomotorSetting.finalInclination"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :disabled="disabled"
        label="Milisegundos de espera"
      >
        <validation-provider
          #default="{ errors }"
          name="milisegundos de espera"
          rules="required|positive"
        >
          <b-form-input
            v-model="computedServomotorSetting.millisecondsToWait"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-button
        :disabled="disabled"
        size="sm"
        variant="success"
        class="mt-50 float-right"
        @click="confirmForm"
      >
        Guardar configuración
        <feather-icon
          class="ml-25"
          icon="CheckIcon"
        />
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, length } from '@validations'
import {
  BForm, BFormInput, BButton, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    servomotorSettingToEdit: {
      required: false,
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      servomotorSetting: {
        name: '',
        alias: '',
        initialInclination: 0,
        finalInclination: 0,
        millisecondsToWait: 0,
      },
      required,
      length,
    }
  },
  computed: {
    computedServomotorSetting() {
      return this.servomotorSettingToEdit ? this.servomotorSettingToEdit : this.servomotorSetting
    },
  },
  methods: {
    confirmForm() {
      this.$refs.deviceForm.validate().then(success => {
        if (success) {
          this.$emit('on-confirm', this.computedServomotorSetting)
        }
      })
    },
    clearForm() {
      this.servomotorSetting = {
        name: '',
        alias: '',
        initialInclination: 0,
        finalInclination: 0,
        millisecondsToWait: 0,
      }
    },
  },
}
</script>
