<template>
  <b-card>
    <b-overlay
      no-wrap
      rounded="lg"
      variant="transparent"
      spinner-variant="primary"
      :show="fetchSettingsLoader || updateSettingsLoader || createNutrientSolutionLoader"
    />
    <div v-if="false">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Humedad máxima"
              label-for="max-humidity"
            >
              <b-form-input
                id="max-humidity"
                v-model.trim="settings.maxHumidity"
                type="text"
                placeholder="Introducir un porcentaje"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Temperatura máxima"
              label-for="max-temperarure"
            >
              <b-form-input
                id="max-temperature"
                v-model.trim="settings.maxTemperature"
                type="text"
                placeholder="Grados celsius"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="pH máximo"
              label-for="max-ph"
            >
              <b-form-input
                id="max-ph"
                v-model.trim="settings.maxPh"
                type="text"
                placeholder="Introducir la medida de pH"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Frecuencia de almacenado de datos"
              label-for="data-storage-frequency"
            >
              <b-form-input
                id="data-storage-frequency"
                v-model.number="settings.dataStorageFrequency"
                type="number"
                placeholder="Intervalo de tiempo (minutos)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Encender bomba de agua durante"
              label-for="water-pump-on"
            >
              <b-form-input
                id="water-pump-on"
                v-model.number="settings.waterPumpOnInterval"
                type="number"
                placeholder="Intervalo de tiempo (minutos)"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Apagar bomba de agua durante"
              label-for="water-pump-off"
            >
              <b-form-input
                id="water-pump-off"
                v-model.number="settings.waterPumpOffInterval"
                type="number"
                placeholder="Intervalo de tiempo (minutos)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="saveChanges"
            >
              Guardar cambios
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1"
              @click="loadSettings"
            >
              Descartar cambios
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <hr class="my-2">
      <b-row>
        <b-col size="8">
          <h4>Configurar solución nutritiva</h4>
        </b-col>
        <b-col size="4">
          <template v-if="!showIngredientForm">
            <b-button
              variant="primary"
              class="float-right mb-2"
              @click="showIngredientForm = true"
            >
              Añadir ingrediente
            </b-button>
          </template>
        </b-col>
      </b-row>
      <b-row v-if="showIngredientForm">
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Nombre del ingrediente"
            label-for="ingredient-name"
          >
            <b-form-input
              id="ingredient-name"
              v-model="ingredient.name"
              type="text"
              placeholder="Ingresar nombre del ingrediente"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Cantidad del ingrediente"
            label-for="ingredient-quantity"
          >
            <b-form-input
              id="ingredient-quantity"
              v-model.number="ingredient.quantity"
              type="number"
              placeholder="Ingresar cantidad del ingrediente (ml)"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <template v-if="showIngredientForm">
            <b-button
              variant="success"
              class="mb-2 mr-1"
              @click="createNutrientSolution"
            >
              Guardar ingrediente
            </b-button>
            <b-button
              variant="danger"
              class="mb-2"
              @click="clearIngredientForm(); showIngredientForm = false;"
            >
              Cancelar
            </b-button>
          </template></b-col>
      </b-row>
    </div>
    <b-row>
      <b-col size="12">
        <b-table
          v-if="false"
          :fields="fields"
          :items="nutrientSolutions"
          show-empty
          empty-text="Sin soluciones configuradas"
        >
          <template #cell(actions)="data">
            <div
              class="text-nowrap"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Abrir configuración'"
                icon="SettingsIcon"
                size="18"
                class="mr-50 text-warning cursor-pointer"
                @click="promptConfigDevice(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Editar dispositivo'"
                icon="EditIcon"
                size="18"
                class="mr-50 text-info cursor-pointer"
                @click="promptEditDevice(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Eliminar dispositivo'"
                icon="TrashIcon"
                size="18"
                class="text-danger cursor-pointer"
                @click="promptDeleteDevice(data.item)"
              />
            </div>
          </template>
        </b-table>
        <h4 class="mb-2">
          <feather-icon
            icon="SettingsIcon"
            size="18"
            class="mr-50 text-warning"
          />
          Configurar servomotores</h4>
        <servomotor-settings-table
          :items="servomotorSettings"
          @on-edit-servomotor-setting="onEditServomotorSetting"
        />
        <update-servomotor-setting-modal
          :servomotor-setting="servomotorSettingToEdit"
          @on-servomotor-setting-updated="fetchServomotorSettings"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BOverlay, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ServomotorSettingsTable from '@/views/apps/device/components/ServomotorSettingsTable.vue'
import UpdateServomotorSettingModal from '@/views/apps/device/components/UpdateServomotorSettingModal.vue'

export default {
  components: {
    UpdateServomotorSettingModal,
    ServomotorSettingsTable,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      servomotorSettingToEdit: {},
      settings: {
        maxHumidity: '',
        maxTemperature: '',
        maxPh: '',
        dataStorageFrequency: '',
        waterPumpOnInterval: '',
        waterPumpOffInterval: '',
      },
      showIngredientForm: false,
      ingredient: {
        name: '',
        quantity: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: false,
        },
        {
          key: 'quantity',
          sortable: false,
          label: 'Cantidad (ml)',
        },
      ],
    }
  },
  computed: {
    servomotorSettings() {
      return this.$store.state.deviceSettingsModule.servomotorSettings
    },
    fetchSettingsLoader() {
      return this.$store.state.deviceSettingsModule.loaders.fetchSettings
    },
    updateSettingsLoader() {
      return this.$store.state.deviceSettingsModule.loaders.updateSettings
    },
    createNutrientSolutionLoader() {
      return this.$store.state.deviceSettingsModule.loaders.createNutrientSolution
    },
    nutrientSolutions() {
      return this.$store.state.deviceSettingsModule.settings.deviceSetting.nutrientSolutions
    },
    deviceSettings() {
      return this.$store.state.deviceSettingsModule.settings.deviceSetting
    },
  },
  mounted() {
    this.loadSettings()
  },
  methods: {
    onEditServomotorSetting(servomotorSettingToEdit) {
      this.servomotorSettingToEdit = servomotorSettingToEdit
      this.$bvModal.show('edit-servomotor-setting-modal')
    },
    fetchServomotorSettings() {
      this.$store.dispatch('deviceSettingsModule/fetchServomotorSettings')
    },
    updateServomotorSetting() {
      this.$store.dispatch('deviceSettingsModule/updateServomotorSetting')
    },
    createNutrientSolution() {
      const { ingredient } = this
      if (!ingredient.name || !ingredient.quantity) return
      this.$store.dispatch('deviceSettingsModule/createNutrientSolution', {
        ingredient,
        settingId: this.deviceSettings.id,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ingrediente añadido',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Los datos se guardaron correctamente',
            },
          })
          this.clearIngredientForm()
          this.showIngredientForm = false
          this.loadSettings()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Algo salió mal...',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Verifica los datos introducidos y tu conexión a internet',
            },
          })
        })
    },
    clearIngredientForm() {
      this.ingredient = {
        name: '',
        quantity: '',
      }
    },
    saveChanges() {
      const { maxHumidity, maxTemperature, maxPh } = this.settings
      if (!maxHumidity.trim() || !maxTemperature.trim() || !maxPh.trim()) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Datos incompletos',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Introduce los datos necesarios para continuar',
          },
        })

        return
      }
      this.$store.dispatch('deviceSettingsModule/updateSettings', {
        ...this.settings,
        deviceId: this.$route.params.deviceId,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Configuración actualizada',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Los datos se guardaron correctamente',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Algo salió mal...',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Verifica los datos introducidos y tu conexión a internet',
            },
          })
        })
    },
    async loadSettings() {
      this.fetchServomotorSettings()
      /* const deviceInformation = await this.$store.dispatch('deviceSettingsModule/fetchSettings', this.$route.params.deviceId)
      if (deviceInformation.deviceSetting) {
        this.settings = deviceInformation.deviceSetting
      } */
    },
  },
}
</script>
