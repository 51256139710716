var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"deviceForm"},[_c('b-form',[_c('b-form-group',{attrs:{"disabled":_vm.disabled,"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.computedServomotorSetting.name),callback:function ($$v) {_vm.$set(_vm.computedServomotorSetting, "name", $$v)},expression:"computedServomotorSetting.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled,"label":"Alias"}},[_c('validation-provider',{attrs:{"name":"alias","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.computedServomotorSetting.alias),callback:function ($$v) {_vm.$set(_vm.computedServomotorSetting, "alias", $$v)},expression:"computedServomotorSetting.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled,"label":"Inclinación inicial"}},[_c('validation-provider',{attrs:{"name":"inclinación inicial","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.computedServomotorSetting.initialInclination),callback:function ($$v) {_vm.$set(_vm.computedServomotorSetting, "initialInclination", $$v)},expression:"computedServomotorSetting.initialInclination"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled,"label":"Inclinación final"}},[_c('validation-provider',{attrs:{"name":"inclinación final","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.computedServomotorSetting.finalInclination),callback:function ($$v) {_vm.$set(_vm.computedServomotorSetting, "finalInclination", $$v)},expression:"computedServomotorSetting.finalInclination"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled,"label":"Milisegundos de espera"}},[_c('validation-provider',{attrs:{"name":"milisegundos de espera","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.computedServomotorSetting.millisecondsToWait),callback:function ($$v) {_vm.$set(_vm.computedServomotorSetting, "millisecondsToWait", $$v)},expression:"computedServomotorSetting.millisecondsToWait"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"mt-50 float-right",attrs:{"disabled":_vm.disabled,"size":"sm","variant":"success"},on:{"click":_vm.confirmForm}},[_vm._v(" Guardar configuración "),_c('feather-icon',{staticClass:"ml-25",attrs:{"icon":"CheckIcon"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }