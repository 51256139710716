<template>
  <section>
    <b-table
      fixed-header
      responsive
      :fields="fields"
      :items="items"
      show-empty
      empty-text="Sin datos para mostrar"
    >
      <template #cell(actions)="data">
        <div
          class="text-nowrap"
        >
          <feather-icon
            v-b-tooltip.hover.top="'Modificar parámetros'"
            icon="EditIcon"
            size="18"
            class="mr-50 text-info cursor-pointer"
            @click="promptEditServomotorSetting(data.item)"
          />
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: false,
        },
        {
          key: 'alias',
          label: 'Alias',
          sortable: false,
        },
        {
          key: 'initialInclination',
          label: 'Inclinación inicial',
          sortable: false,
        },
        {
          key: 'finalInclination',
          label: 'Inclinación final',
          sortable: false,
        },
        {
          key: 'millisecondsToWait',
          label: 'Milisegundos de espera',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Acciones',
        },
      ],
    }
  },
  methods: {
    getDate(date) {
      return date ? this.$moment(date).format('LLL') : ''
    },
    promptDeleteDevice(device) {
      this.$emit('on-delete-device', device)
    },
    promptEditServomotorSetting(servomotorSetting) {
      this.$emit('on-edit-servomotor-setting', servomotorSetting)
    },
    promptConfigDevice(device) {
      this.$emit('on-config-device', device)
    },
  },
}
</script>
